<template>
  <div :class="prefixCls">
    <div ref="editor" class="editor-wrapper"></div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import WEditor from 'wangeditor'
import consts from '@/constants'

export default {
  name: 'WangEditor',

  props: {
    prefixCls: {
      type: String,
      default: 'ant-editor-wang'
    },
    // eslint-disable-next-line
    value: {
      type: String
    },
    isClear: {
      type: Boolean,
      default: false
    },
    needBank: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => {
        return [
          'head', // 标题
          'bold', // 粗体
          'fontSize', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          'strikeThrough', // 删除线
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'quote', // 引用
          //  'emoticon', // 表情
          'image', // 插入图片
          'table', // 表格
          //  'video', // 插入视频
          'code', // 插入代码
          'undo', // 撤销
          'redo'
        ]
      }
    },
  },

  data () {
    return {
      editor: null,
      editorContent: null,
      isChange:false,
    }
  },

  computed:{
    ...mapGetters([
      'token',
    ])
  },

  watch: {
    'isClear': function(newVal,oldVal) {
      if (this.editor){
        this.editor.txt.html('')
      } else {
        var waitEditor = setInterval(()=>{
          if(this.editor) {
            this.editor.txt.html('')
            clearInterval(waitEditor)
          }
        },200)
      }
    },

    value: {
      handler(newVal,oldVal) {

        if(!this.isChange && newVal && newVal != ''){

          if (this.editor)
            this.editor.txt.html(newVal)
          else {
            var waitEditor = setInterval(()=>{
              if(this.editor) {
                this.editor.txt.html(newVal)
                clearInterval(waitEditor)
              }
            },200)
          }
        }

        this.isChange= false
      },
      immediate:true
    }
  },

  mounted () {
    this.initEditor()
  },

  methods: {
    initEditor () {
      var editor = new WEditor(this.$refs.editor)

      // this.editor.onchangeTimeout = 200
      editor.config.onchange = (html) => {
        if (this.value && this.value != '')
          this.isChange = true
        this.editorContent = html
        this.$emit('change', this.editorContent)
      }
      editor.config.pasteFilterStyle = true
      editor.config.pasteIgnoreImg = false

      editor.config.uploadImgTimeout = 300000
      editor.config.uploadImgMaxSize = 20 * 1024 * 1024
      editor.config.uploadImgMaxLength = 5
      editor.config.uploadFileName = 'file'
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
      editor.config.uploadImgHeaders = {
        'Access-Token': this.token
      }
      editor.config.uploadImgServer = '/api' + consts.url.UPLOAD_QUES_IMG_WANGEDITOR
      editor.config.showFullScreen = false
      editor.config.menus = this.menus

      this.editor = editor

      //配置菜单
      const { BtnMenu } = WEditor

      // 创建 class
      class insertBlankMenu extends BtnMenu {
        constructor(editor) {
          const $elem = WEditor.$(
            `
	                        <div class="w-e-menu" style="width:80px;color:#e93d52;">
	                        <span class="el-icon-upload2 blank">插入填空区</span>
	                        </div>
	                    `
          )
          super($elem, editor)
        }

        // 自定义菜单点击事件
        clickHandler() {
          editor.cmd.do('insertHTML', '（   ）')
        }

        //激活
        tryChangeActive() {}
      }

      // 注册菜单

      if (this.needBank) {
        this.editor.menus.extend("insertBlank", insertBlankMenu)
        this.editor.config.menus = this.editor.config.menus.concat("insertBlank")
      }

      this.editor.create()
    }
  }
}
</script>

<style scoped>
.ant-editor-wang .editor-wrapper {
  text-align: left;
}
/deep/ .blank{
  background-color: #1d88fa;
  color: white;
  height: 25px;
  line-height: 25px;
  padding: 0 5px;
  border-radius: 3px;
}

/deep/ .w-e-text{
  overflow-y:auto;
}

</style>
