import { mapGetters, mapMutations, mapActions } from 'vuex'
import { doQuery, doAction } from "@/api/core";
import WangEditor from '@/components/Editor/WangEditor.vue'

export default {
    name: "distributeKnowledge",
    data() {
        return {
            distribute_form: {
                kdbtype_code: undefined,
                title: undefined,
                create_type: 'edit',
                intro: '',
                video_url: '',
            },
            clear_rich_text: false,
            distribute_form_rules: {
                kdbtype_code: [
                    { required: true, message: '请选择知识类型', trigger: 'change' }
                ],
                title: [
                    { required: true, message: '请填写知识名称', trigger: 'blur' },
                    { min: 1, max: 100, message: '字数限制1-100', trigger: 'blur' },
                ],
                create_type: [{ required: true, message: '请选择创建方式', trigger: 'change' }],
                intro: [
                    { required: true, message: '请填写知识内容', trigger: 'blur' },
                    { min: 1, max: 64000, message: '字数限制1-64000', trigger: 'blur' },
                ],
                video_url: [
                    { required: true, message: '请上传文件', trigger: 'change' },
                ],
            },
            knowledge_types: [

            ],
            ktypeList: [],
            ksubtypeList: [],
            ktypeSelectedId: '',
            ksubtypeSelectedId: '',

            fileList:[],
        }
    },
    components: {
        WangEditor
    },
    computed: {
        ...mapGetters([
            'token',
            'mobilephone',
            'getPkdbtypes',
            'getKdbtypes',
            'staffId'
        ])
    },
    mounted() {
        const that = this
        that.RefreshPkdbtypes()
            .then(() => {
                var typelist = []
                that.getPkdbtypes.forEach((item, index) => {
                    typelist.push({
                        id: item.pkdbtype_code,
                        title: item.pkdbtype
                    })
                })
                /*类型与子类型*/
                that.ktypeList = typelist
                that.ksubtypeList = []
                that.RefreshKdbtypes()
                    .then(() => {
                        that.ktypeSelectedId = that.ktypeList[0].id
                        that.ksubtypeSelectedId = ''
                        //获得子类型列表
                        that.ksubtypeList = [];

                        that.GetKdbtypesByPkdbtypeCode(that.ktypeSelectedId)
                            .then(res => {
                                res.forEach(function(item, index) {
                                    that.ksubtypeList.push({
                                        id: item.kdbtype_code,
                                        title: item.kdbtype
                                    })
                                })
                            })
                    })
            })
    },
    methods: {
        ...mapActions([
            'RefreshPkdbtypes',
            'RefreshKdbtypes',
            'GetKdbtypesByPkdbtypeCode',
            'GetKdbtypeCodesByPkdbtypeCode',
            'GetPkdbtypeByKdbtypeCode',
            'GetTxttypeByKdbtypeCode'
        ]),
        //  editor监听
        wangEditorChange(content) {
            const that = this
            that.distribute_form.intro = content
        },
        handleUploadChange({ file, fileList, event }) {
            const that = this
            var form = that.distribute_form
            let fl = [...fileList]
            fl = fl.slice(-1)
            if (fl && fl.length > 0) {
                if (fl[0].status == 'done') {
                    if (fl[0].response.errcode == 0) {
                        form.video_url = fl[0].response.data.url
                        fl[0].url = fl[0].response.data.url
                    } else {
                        form.video_url = ''
                    }
                } else if (fl[0].status == 'removed') {
                    form.video_url = ''
                }
            } else {
                form.video_url = ''
            }
            that.distribute_form = form
            //更新下显示的文件
            that.fileList = fl
        },
        submitDistributeKnowledge() {
            const that = this
            console.log(that.distribute_form)
        },
        resetDistributeKnowledgeForm() {
            const that = this
            that.distribute_form = {
                kdbtype_code: undefined,
                title: undefined,
                create_type: 'edit',
                intro: '',
                video_url: '',
            }
            that.fileList = []
        }
    }
}
